// Import necessary libraries and components

import { isMobile, Link } from '@imtbl/imx-sdk';
// Component with a clickable link to trigger adding funds

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

const FundsComponent = () => {
  // Handler for the click event
  const handleAddFundsClick = async () => {
    // Initialize Link

  

    const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };


    let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);

    try {
      // Call the method for adding funds
      let result = await link.fiatToCrypto({});
      // Print the result
      //console.log(result);
    } catch (error) {
      // Catch and print out the error
      console.error(error);
    }
  };


  return (
    // Clickable link to trigger adding funds
    <span 
    onClick={handleAddFundsClick}>
      Add Ethereum Funds
    </span>
  );
};



export default FundsComponent;
